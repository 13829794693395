<template>
  <v-app class="app_background">
    <!--<TopToolbar v-if="this.$route.name !== 'home'"></TopToolbar>-->
    <v-content fluid>
      <!--<WidgetsMenu v-if="this.$route.name !== 'home'"></WidgetsMenu>-->
      <router-view color="primary"></router-view>
    </v-content>
  </v-app>
</template>

<script>
import { Vue } from "vue-property-decorator";
import TopToolbar from "./components/TopToolbar";
//import WidgetsMenu from "./components/WidgtesMenu.vue";
import VueTouch from "vue-touch";

Vue.use(VueTouch);
export default {
  name: "controlboard-vuetify",
  components: {
    TopToolbar,
    //WidgetsMenu,
  },
  created() {},
};
</script>

<style lang="scss">
.primary_container {
  background-color: var(--v-primary-base);
}

@import "../node_modules/leaflet/dist/leaflet.css";

.leaflet-fake-icon-image-2x {
  background-image: url(../node_modules/leaflet/dist/images/marker-icon-2x.png);
}
.leaflet-fake-icon-shadow {
  background-image: url(../node_modules/leaflet/dist/images/marker-shadow.png);
}

.v-content__wrap {
  overflow: auto !important;
}

html {
  overflow: hidden;
}
</style>

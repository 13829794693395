export class amuraEvent {
  public id: number;
  public version: string;
  public time: Date;
  public reportedAt: Date;
  public reportedBy: Date;
  public objectType: string;
  public eventCode: string;
  public objectId: string;
  public additionalData: Object;

  constructor(
    id: number,
    version: string,
    time: Date,
    reportedAt: Date,
    reportedBy: Date,
    objectType: string,
    eventCode: string,
    objectId: string,
    additionalData: Object
  ) {
    this.id = id;
    this.version = version;
    this.time = time;
    this.reportedAt = reportedAt;
    this.reportedBy = reportedBy;
    this.objectType = objectType;
    this.eventCode = eventCode;
    this.objectId = objectId;
    this.additionalData = additionalData;
  }
}

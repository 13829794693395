<template>
  <div class="login">
    <v-form action class="form" ref="form">
      <div class="logoContainer">
        <img src="../assets/logo-controlboard.png" class="logoCB" />
      </div>
      <v-text-field
        solo
        flat
        rounded
        v-model="email"
        :rules="emailRules"
        color="primary"
        type="email"
        class="loginInput"
        required
      >
        <v-icon slot="prepend-inner" color="primary">
          mdi-account-outline
        </v-icon>
      </v-text-field>
      <v-text-field
        solo
        flat
        rounded
        v-model="password"
        :rules="passwordRules"
        color="primary"
        type="password"
        class="loginInput"
        required
      >
        <v-icon slot="prepend-inner" color="primary">
          mdi-lock-outline
        </v-icon>
      </v-text-field>
      <v-btn :disabled="!valid" color="primary" @click="validate">
        LOGIN
      </v-btn>
    </v-form>
    <v-snackbar v-model="snackbar" color="error">
      {{ snackbarText }}
    </v-snackbar>
  </div>
</template>

<script>
import authService from "@/services/authService";
export default {
  data: () => ({
    valid: true,
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    password: "",
    passwordRules: [(v) => !!v || "password is required"],
    snackbar: false,
    snackbarText: "",
  }),
  methods: {
    validate() {
      if (this.$refs.form.validate()) this.login();
    },
    async login() {
      try {
        await authService
          .login(this.email, this.password)
          .then((response) => {
            const userAuth = response.data.success;
            if (userAuth.token) {
              authService.setUserLogged(userAuth);
              this.$router.push("/mapa");
            }
          })
          .catch((error) => {
            if (error.response.status == 401) {
              this.snackbarText = "El usuario o la contraseña son incorrectos";
              this.snackbar = true;
            } else if (error.response.status == 403) {
              this.snackbarText = "Este usuario no tiene acceso permitido";
              this.snackbar = true;
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  padding: 2rem;
}

.form {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 60%;
  min-width: 300px;
  max-width: 100%;
  padding: 40px;
}

.v-input {
  width: 70%;
}

.v-btn {
  width: 30%;
  border-radius: 6px;
}

.logoContainer {
  width: 100%;
  display: flex;
  justify-content: center;
}

.logoCB {
  margin: 10px;
  margin-bottom: 30%;
  max-width: 120%;
}

@media screen and (max-width: 950px) {
  .logoCB {
    margin: 0px;
    margin-bottom: 5%;
    max-width: 50%;
  }
}

@media (max-aspect-ratio: 1/1) {
  .form {
    margin-top: 25%;
  }
}
</style>

<style lang="scss">
.theme--light.v-text-field--solo.loginInput
  > .v-input__control
  > .v-input__slot {
  border-radius: 6px;
}
</style>
